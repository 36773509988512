<template>
  <v-main>
    <v-container fluid>
      <v-breadcrumbs :items="breadcrumbs" />

      <v-card>
        <v-card-title>
          Obat
          <v-spacer />
          <v-text-field
            v-model="search"
            append-icon="mdi-magnify"
            label="Cari Obat"
            single-line
            hide-details
            @keyup.enter="getDataFromApi"
          />
          <v-spacer />

          <v-dialog v-model="dialog" persistent max-width="600px">
            <template #activator="{}">
              <v-btn color="primary" dark @click="doAdd"> Tambah </v-btn>
            </template>
            <v-card>
              <v-card-title>
                <span class="text-h5">Obat</span>
              </v-card-title>
              <v-card-text>
                <v-container>
                  <v-row>
                    <v-col cols="12">
                      <v-text-field
                        v-model="currentData.nama"
                        label="Nama Obat"
                        required
                      />
                    </v-col>
                    <v-col cols="12">
                      <v-textarea
                        v-model="currentData.deskripsi"
                        outlined
                        label="Deskripsi"
                        required
                        rows="5"
                      ></v-textarea>
                    </v-col>
                  </v-row>
                </v-container>
              </v-card-text>
              <v-card-actions>
                <v-spacer />
                <v-btn color="blue darken-1" text @click="dialog = false">
                  Close
                </v-btn>
                <v-btn color="blue darken-1" text @click="doSave"> Save </v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>

          <v-dialog v-model="dialogDelete" max-width="500px">
            <v-card>
              <v-card-title class="text-h5">
                Apa anda akan menghapus data ini?
              </v-card-title>
              <v-card-actions>
                <v-spacer />
                <v-btn color="blue darken-1" text @click="closeDelete">
                  Tidak
                </v-btn>
                <v-btn color="blue darken-1" text @click="deleteItemConfirm">
                  Ya
                </v-btn>
                <v-spacer />
              </v-card-actions>
            </v-card>
          </v-dialog>
        </v-card-title>
        <v-data-table
          v-model="selected"
          :headers="headers"
          :items="list_data"
          :options.sync="options"
          :server-items-length="totalData"
          :loading="loading"
          class="elevation-1"
          :single-select="true"
          show-select
        >
          <template #[`item.deskripsi`]="{ item }">
            <div>{{ ellipseText(item.deskripsi) }}</div>
          </template>

          <template #[`item.actions`]="{ item }">
            <v-icon medium class="mr-3" @click="editItem(item)">
              mdi-pencil
            </v-icon>
            <v-icon medium @click="deleteItem(item)"> mdi-delete </v-icon>
          </template>
        </v-data-table>
      </v-card>

      <v-card class="mt-4">
        <KemasanObatComponent
          v-if="selected != undefined && selected.length > 0"
          :id="selected[0].id"
          ref="mychild"
          :key="childKey"
        />
      </v-card>
    </v-container>
  </v-main>
</template>

<script setup>
import { ref, onMounted, watch } from "vue";

import KemasanObatComponent from "./KemasanObat.vue";

import ObatService from "../../services/master/ObatService";
import CommonService from "../../services/CommonService";

const breadcrumbs = ref([
  {
    text: "Data Obat",
    disabled: true,
    href: "breadcrumbs_dashboard",
  },
]);

const selected = ref([]);
const childKey = ref(1);

watch(
  selected,
  (newVal) => {
    if (newVal !== undefined && newVal[0]) {
      childKey.value++;
    }
  },
  { deep: true },
);

const search = ref("");
const headers = ref([
  {
    text: "No",
    align: "start",
    sortable: false,
    value: "no",
  },
  { text: "Nama", value: "nama", sortable: false },
  { text: "Deskripsi", value: "deskripsi", sortable: false },
  { text: "Actions", value: "actions", sortable: false },
]);
const totalData = ref(0);
const list_data = ref([]);
const loading = ref(false);
const options = ref({
  itemsPerPage: 10,
});
const editedIndex = ref(-1);
const editedItem = ref(null);

const dialog = ref(false);
const dialogDelete = ref(false);

const currentData = ref({
  id: null,
  nama: "",
  deskripsi: "",
});

function ellipseText(text, length = 80) {
  if (!text) return "";
  return text.length > length ? text.substring(0, length) + "..." : text;
}

function getDataFromApi() {
  const { page, itemsPerPage } = options.value;

  loading.value = true;
  ObatService.obatList(page, itemsPerPage, search.value)
    .then((res) => {
      list_data.value = res.data.list_data;
      totalData.value = res.data.total;
      loading.value = false;
    })
    .catch((err) => {
      loading.value = false;
      console.log(err);
    });
}

function doAdd() {
  dialog.value = true;
  currentData.value.nama = "";
  currentData.value.deskripsi = "";
  currentData.value.id = null;
}

function doSave() {
  if (currentData.value.id == null) {
    ObatService.create(currentData.value)
      .then((res) => {
        console.log(res);
        dialog.value = false;

        CommonService.showSuccessToast("Tambah obat berhasil");

        getDataFromApi();
      })
      .catch((err) => {
        console.log(err);
        CommonService.showErrorToast("Tambah obat gagal. Coba lagi");
      });
  } else {
    ObatService.update(currentData.value.id, currentData.value)
      .then((res) => {
        dialog.value = false;

        console.log(res);
        CommonService.showSuccessToast("Update obat berhasil");

        getDataFromApi();
      })
      .catch((err) => {
        console.log(err);
        CommonService.showErrorToast("Update obat gagal. Coba lagi");
      });
  }
}

function editItem(item) {
  editedIndex.value = list_data.value.indexOf(item);
  editedItem.value = Object.assign({}, item);

  dialog.value = true;
  currentData.value.nama = editedItem.value.nama;
  currentData.value.deskripsi = editedItem.value.deskripsi;
  currentData.value.id = editedItem.value.id;
}

function deleteItem(item) {
  editedIndex.value = list_data.value.indexOf(item);
  editedItem.value = Object.assign({}, item);
  dialogDelete.value = true;
}

function deleteItemConfirm() {
  closeDelete();

  ObatService.delete(editedItem.value.id)
    .then((res) => {
      console.log(res);

      CommonService.showSuccessToast("Hapus obat berhasil");

      getDataFromApi();
    })
    .catch((err) => {
      console.log(err);
      CommonService.showSuccessToast("Hapus obat gagal. Coba lagi");
    });
}

function closeDelete() {
  dialogDelete.value = false;
}

onMounted(async () => {
  getDataFromApi();
});
</script>
