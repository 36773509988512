<template>
  <div>
    <v-card>
      <v-card-title>
        Kemasan Obat
        <v-spacer />
        <v-text-field
          v-model="search"
          append-icon="mdi-magnify"
          label="Cari Kemasan"
          single-line
          hide-details
          @keyup.enter="getDataFromApi"
        />
        <v-spacer />

        <v-dialog v-model="dialog" persistent max-width="600px">
          <template #activator="{}">
            <v-btn color="primary" dark @click="doAdd"> Tambah </v-btn>
          </template>
          <v-card>
            <v-card-title>
              <span class="text-h5">Obat</span>
            </v-card-title>
            <v-card-text>
              <v-form ref="myForm" v-model="validForm">
                <v-container>
                  <v-row>
                    <v-col cols="12">
                      <v-combobox
                        v-model="currentData.selectedKemasan"
                        label="Jenis Kemasan"
                        clearable
                        dense
                        outlined
                        :items="itemsKemasan"
                        item-text="value"
                        :rules="jenisKemasanRules"
                      ></v-combobox>
                    </v-col>
                    <v-col cols="12">
                      <v-text-field
                        v-model="currentData.harga"
                        label="Harga"
                        required
                        type="Number"
                        :rules="hargaRules"
                      />
                    </v-col>
                  </v-row>
                </v-container>
              </v-form>
            </v-card-text>
            <v-card-actions>
              <v-spacer />
              <v-btn color="blue darken-1" text @click="dialog = false">
                Close
              </v-btn>
              <v-btn color="blue darken-1" text @click="doSave"> Save </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>

        <v-dialog v-model="dialogDelete" max-width="500px">
          <v-card>
            <v-card-title class="text-h5">
              Apa anda akan menghapus data ini?
            </v-card-title>
            <v-card-actions>
              <v-spacer />
              <v-btn color="blue darken-1" text @click="closeDelete">
                Tidak
              </v-btn>
              <v-btn color="blue darken-1" text @click="deleteItemConfirm">
                Ya
              </v-btn>
              <v-spacer />
            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-card-title>
      <v-data-table
        :headers="headers"
        :items="list_data"
        :options.sync="options"
        :server-items-length="totalData"
        :loading="loading"
        class="elevation-1"
      >
        <template #[`item.harga`]="{ item }">
          <div>
            {{
              Intl.NumberFormat("id-ID", {
                style: "currency",
                currency: "IDR",
                minimumFractionDigits: 0,
              })
                .format(item.harga)
                .replace(/Rp\s?/, "")
            }}
          </div>
        </template>

        <template #[`item.actions`]="{ item }">
          <v-icon medium class="mr-3" @click="editItem(item)">
            mdi-pencil
          </v-icon>
          <v-icon medium @click="deleteItem(item)"> mdi-delete </v-icon>
        </template>
      </v-data-table>
    </v-card>
  </div>
</template>

<!-- eslint-disable vue/require-default-prop -->
<script setup>
import { ref, onMounted } from "vue";

import KemasanObatService from "../../services/master/KemasanObatService";
import CommonService from "../../services/CommonService";
import MasterValueService from "../../services/master/MasterValueService";

const jenisKemasanRules = ref([(v) => !!v || "Jenis Kemasan harus diisi"]);
const hargaRules = ref([(v) => !!v || "Harga harus diisi"]);

const props = defineProps({
  id: [Number, String],
});

var myForm = ref(null);
const validForm = ref(false);

const search = ref("");
const headers = ref([
  {
    text: "No",
    align: "start",
    sortable: false,
    value: "no",
  },
  { text: "Jenis Kemasan", value: "jenis_kemasan_value", sortable: false },
  { text: "Harga", value: "harga", sortable: false },
  { text: "Actions", value: "actions", sortable: false },
]);
const totalData = ref(0);
const list_data = ref([]);
const loading = ref(false);
const options = ref({
  itemsPerPage: 10,
});
const editedIndex = ref(-1);
const editedItem = ref(null);

const dialog = ref(false);
const dialogDelete = ref(false);

const currentData = ref({
  id: null,
  selectedKemasan: "",
  harga: "",
});

const itemsKemasan = ref([]);

function getDataFromApi() {
  const { page, itemsPerPage } = options.value;

  loading.value = true;
  KemasanObatService.kemasanObatList(page, itemsPerPage, search.value, props.id)
    .then((res) => {
      list_data.value = res.data.list_data;
      totalData.value = res.data.total;
      loading.value = false;
    })
    .catch((err) => {
      loading.value = false;
      console.log(err);
    });
}

function doAdd() {
  dialog.value = true;
  currentData.value.selectedKemasan = null;
  currentData.value.harga = "";
  currentData.value.id = null;
}

async function doSave() {
  console.log(validForm.value);

  if (!validForm.value) return;

  if (currentData.value.id == null) {
    currentData.value.obat_id = props.id;
    KemasanObatService.create(currentData.value)
      .then((res) => {
        console.log(res);
        dialog.value = false;

        CommonService.showSuccessToast("Tambah Kemasan obat berhasil");

        getDataFromApi();
      })
      .catch((err) => {
        console.log(err);
        CommonService.showErrorToast("Tambah Kemasan obat gagal. Coba lagi");
      });
  } else {
    KemasanObatService.update(currentData.value.id, currentData.value)
      .then((res) => {
        dialog.value = false;

        console.log(res);
        CommonService.showSuccessToast("Update Kemasan obat berhasil");

        getDataFromApi();
      })
      .catch((err) => {
        console.log(err);
        CommonService.showErrorToast("Update Kemasan obat gagal. Coba lagi");
      });
  }
}

function editItem(item) {
  editedIndex.value = list_data.value.indexOf(item);
  editedItem.value = Object.assign({}, item);

  dialog.value = true;
  currentData.value.jenis_kemasan_id = editedItem.value.jenis_kemasan_id;

  if (currentData.value.jenis_kemasan_id) {
    itemsKemasan.value.forEach((entry) => {
      if (entry.code == currentData.value.jenis_kemasan_id) {
        currentData.value.selectedKemasan = entry;
      }
    });
  }

  currentData.value.harga = editedItem.value.harga;
  currentData.value.id = editedItem.value.id;
}

function deleteItem(item) {
  editedIndex.value = list_data.value.indexOf(item);
  editedItem.value = Object.assign({}, item);
  dialogDelete.value = true;
}

function deleteItemConfirm() {
  closeDelete();

  KemasanObatService.delete(editedItem.value.id)
    .then((res) => {
      console.log(res);

      CommonService.showSuccessToast("Hapus Kemasan obat berhasil");

      getDataFromApi();
    })
    .catch((err) => {
      console.log(err);
      CommonService.showSuccessToast("Hapus Kemasan obat gagal. Coba lagi");
    });
}

function closeDelete() {
  dialogDelete.value = false;
}

async function getJenisKemasan() {
  await MasterValueService.jenisKemasan()
    .then((res) => {
      itemsKemasan.value = res.data.list_data;
    })
    .catch((err) => {
      console.log(err);

      CommonService.showErrorToast("Gagal mengambil data. Muat ulang halaman");
    });
}

onMounted(async () => {
  getDataFromApi();
  getJenisKemasan();
});
</script>
