import http from "../../http-common";
/* eslint-disable */
class KemasanObatService {
  kemasanObatList(page, itemPerPage, keyword, obatId) {
    return http.get(
      "/kemasan-obat/list?page=" +
        page +
        "&item_per_page=" +
        itemPerPage +
        "&keyword=" +
        keyword +
        "&obat_id=" +
        obatId,
      {
        headers: {
          "Content-type": "application/json",
          Authorization:
            "Bearer " + localStorage.getItem(process.env.VUE_APP_TOKEN_PREFIX),
        },
      },
    );
  }

  create(data) {
    var bodyFormData = new FormData();
    bodyFormData.append("jenis_kemasan_id", data.selectedKemasan.code);
    bodyFormData.append("harga", data.harga);
    bodyFormData.append("obat_id", data.obat_id);

    return http.post("/kemasan-obat", bodyFormData, {
      headers: {
        "Content-Type": "multipart/form-data",
        Authorization:
          "Bearer " + localStorage.getItem(process.env.VUE_APP_TOKEN_PREFIX),
      },
    });
  }

  update(id, data) {
    var bodyFormData = new FormData();
    bodyFormData.append("id", id);
    bodyFormData.append("jenis_kemasan_id", data.selectedKemasan.code);
    bodyFormData.append("harga", data.harga);

    return http.put(`/kemasan-obat/${id}`, bodyFormData, {
      headers: {
        "Content-Type": "multipart/form-data",
        Authorization:
          "Bearer " + localStorage.getItem(process.env.VUE_APP_TOKEN_PREFIX),
      },
    });
  }

  delete(id) {
    return http.delete(`/kemasan-obat/${id}`, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
  }
}

export default new KemasanObatService();
