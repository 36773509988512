<template>
  <v-main>
    <v-container fluid>
      <v-progress-linear
        v-if="isProgressBarShow"
        indeterminate
        color="green"
      ></v-progress-linear>
      <v-breadcrumbs :items="breadcrumbs"></v-breadcrumbs>
      <v-card>
        <v-card-title>
          Nakes
          <v-spacer></v-spacer>

          <router-link
            to="/nakes-add"
            style="text-decoration: none; color: inherit"
          >
            <v-btn color="teal" dark class=""> Tambah </v-btn>
          </router-link>
          <v-btn color="purple" dark class="ms-2" @click="onDownloadPeserta">
            Download
          </v-btn>
          <v-dialog v-model="dialogDelete" max-width="500px">
            <v-card>
              <v-card-title class="text-h5"
                >Apa anda akan menghapus data ini?</v-card-title
              >
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="blue darken-1" text @click="closeDelete"
                  >Tidak</v-btn
                >
                <v-btn color="blue darken-1" text @click="deleteItemConfirm"
                  >Ya</v-btn
                >
                <v-spacer></v-spacer>
              </v-card-actions>
            </v-card>
          </v-dialog>
        </v-card-title>

        <v-row class="mx-2 my-4">
          <v-col cols="12" sm="4" md="4">
            <v-text-field
              v-model="search"
              append-icon="mdi-magnify"
              label="Cari Nama"
              single-line
              outlined
              dense
              hide-details
              @keyup.enter="getDataFromApi"
            ></v-text-field>
          </v-col>
          <v-col cols="12" sm="4" md="4">
            <v-combobox
              v-model="selectedPoli"
              label="Poli"
              clearable
              dense
              outlined
              :items="items_poli"
              item-text="nama"
              item-value="id"
              @change="changePoli"
            ></v-combobox>
          </v-col>
          <v-col cols="12" sm="4" md="4">
            <v-combobox
              v-model="selectedNakes"
              label="Profesi"
              clearable
              dense
              outlined
              :items="items_nakes"
              item-text="value"
              item-value="id"
              @change="changeNakes"
            ></v-combobox>
          </v-col>
        </v-row>

        <div class="pb-6 px-4">
          <v-data-table
            :headers="headers"
            :items="list_data"
            :options.sync="options"
            :server-items-length="totalData"
            :loading="loading"
            class="elevation-1"
          >
            <template #[`item.actions`]="{ item }">
              <v-icon medium class="mr-3" @click="editItem(item)">
                mdi-pencil
              </v-icon>
              <v-icon medium @click="deleteItem(item)"> mdi-delete </v-icon>
            </template>
          </v-data-table>
        </div>
      </v-card>
    </v-container>
  </v-main>
</template>

<script>
import NakesService from "../services/NakesService";
import ProfesiService from "../services/ProfesiService";
import CommonService from "../services/CommonService";
// import DPWService from "../services/DPWService";
// import DPDService from "../services/DPDService";

export default {
  data: () => ({
    isProgressBarShow: false,
    breadcrumbs: [
      {
        text: "Data Tenaga Kesehatan",
        disabled: true,
        href: "breadcrumbs_dashboard",
      },
    ],
    search: "",
    selectedPoli: "",
    selectedNakes: "",
    items_poli: [],
    items_nakes: [],
    headers: [
      {
        text: "No",
        align: "start",
        sortable: false,
        value: "no",
      },
      { text: "Nama", value: "nama", sortable: false },
      // { text: "Poli", value: "poli_nama", sortable: false },
      { text: "No Telp", value: "phone", sortable: false },
      { text: "NIK", value: "no_ktp", sortable: false },
      { text: "STR", value: "str", sortable: false },
      { text: "Profesi", value: "profesi_nama", sortable: false },
      { text: "Actions", value: "actions", sortable: false },
    ],
    isFirst: false,
    totalData: 0,
    list_data: [],
    loading: true,
    options: {
      itemsPerPage: 10,
    },

    editedIndex: -1,
    editedItem: {},
    dialogDelete: false,
  }),
  watch: {
    options: {
      handler() {
        if (this.isFirst) {
          this.getDataFromApi();
        }
      },
      deep: true,
    },
  },
  mounted() {
    this.getListPoli();
    this.getListNakes();
    this.getDataFromApi();
  },
  methods: {
    changePoli() {
      this.getDataFromApi();
    },

    changeNakes() {
      this.getDataFromApi();
    },

    async getListPoli() {
      NakesService.poliList()
        .then((res) => {
          this.items_poli = res.data.list_data;
        })
        .catch((err) => {
          console.log(err);
        });
    },

    async getListNakes() {
      ProfesiService.profesiListAll()
        .then((res) => {
          this.items_nakes = res.data.list_data;
        })
        .catch((err) => {
          console.log(err);
        });
    },

    getDataFromApi() {
      const { page, itemsPerPage } = this.options;
      var poli_id = "";
      var profesi_id = "";

      if (this.selectedPoli) {
        poli_id = this.selectedPoli.id;
      }

      if (this.selectedNakes) {
        profesi_id = this.selectedNakes.code;
      }

      this.loading = true;
      NakesService.nakesList(
        page,
        itemsPerPage,
        this.search,
        poli_id,
        profesi_id,
      )
        .then((res) => {
          this.list_data = res.data.list_data;
          this.totalData = res.data.total;
          this.loading = false;
          this.isFirst = true;
        })
        .catch((err) => {
          this.loading = false;
          this.isFirst = true;
          console.log(err);
        });
    },

    editItem(item) {
      this.editedIndex = this.list_data.indexOf(item);
      this.editedItem = Object.assign({}, item);

      this.$router.push("/nakes-edit/" + item.id).catch((err) => {
        console.log(err);
      });
    },

    deleteItem(item) {
      this.editedIndex = this.list_data.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.dialogDelete = true;
    },

    deleteItemConfirm() {
      this.closeDelete();

      NakesService.delete(this.editedItem.id)
        .then(() => {
          this.$toast.success("Hapus Nakes Berhasil!", {
            position: "top-right",
            timeout: 5000,
            closeOnClick: true,
            pauseOnFocusLoss: true,
            pauseOnHover: true,
            draggable: true,
            draggablePercent: 0.6,
            showCloseButtonOnHover: false,
            hideProgressBar: true,
            closeButton: "button",
            icon: true,
            rtl: false,
          });

          this.getDataFromApi();
        })
        .catch((err) => {
          console.log(err);
          this.$toast.error("Hapus Nakes gagal. Coba lagi!", {
            position: "top-right",
            timeout: 5000,
            closeOnClick: true,
            pauseOnFocusLoss: true,
            pauseOnHover: true,
            draggable: true,
            draggablePercent: 0.6,
            showCloseButtonOnHover: false,
            hideProgressBar: true,
            closeButton: "button",
            icon: true,
            rtl: false,
          });
        });
    },

    closeDelete() {
      this.dialogDelete = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },

    onDownloadPeserta() {
      this.isProgressBarShow = true;

      NakesService.downloadPesertaReport(this.id)
        .then((res) => {
          window.open(res.data.file, "_blank");
          this.isProgressBarShow = false;
        })
        .catch((err) => {
          this.isProgressBarShow = false;
          CommonService.showErrorToast("Gagal download report. Coba lagi");
          console.log(err);
        });
    },
  },
};
</script>
