<template>
  <div>
    <v-progress-linear
      v-if="isProgressBarShow"
      indeterminate
      color="green"
    ></v-progress-linear>
    <v-card>
      <v-card-title>
        Peserta <br />

        <v-spacer></v-spacer>
        <v-text-field
          v-model="search"
          append-icon="mdi-magnify"
          label="Cari Nama"
          single-line
          hide-details
          @keyup.enter="getDataFromApi"
        ></v-text-field>
        <v-spacer></v-spacer>

        <VBtn color="primary" class="my-auto" @click="openUpload">
          Upload Peserta</VBtn
        >

        <v-btn color="primary" dark class="ml-2" @click="downloadSample">
          Download Sample Upload
        </v-btn>

        <!-- <v-btn color="primary" dark class="ml-2" @click="openTambah">
          Tambah
        </v-btn> -->

        <v-btn color="primary" dark class="ml-2" @click="openDeleteAll">
          Hapus Peserta
        </v-btn>
        <v-dialog v-model="dialog" persistent max-width="800px">
          <v-card>
            <v-card-title>
              <span class="text-h5">Peserta</span>
            </v-card-title>
            <v-card-text>
              <v-form
                ref="form"
                v-model="valid"
                lazy-validation
                class="pl-4 pr-4"
              >
                <v-container>
                  <v-row>
                    <v-col cols="12">
                      <v-text-field
                        v-model="currentData.nama"
                        label="Nama"
                        required
                        :rules="currentData.namaRules"
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12">
                      <v-text-field
                        v-model="currentData.whatsapp"
                        label="Whatsapp"
                        dense
                        required
                        :rules="currentData.whatsappRules"
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12">
                      <v-text-field
                        v-model="currentData.email"
                        label="Email"
                        dense
                        required
                      ></v-text-field>
                    </v-col>
                  </v-row>
                </v-container>
              </v-form>
            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="blue darken-1" text @click="dialog = false">
                Close
              </v-btn>
              <v-btn color="blue darken-1" text @click="doSave"> Save </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
        <v-dialog v-model="dialogDelete" max-width="500px">
          <v-card>
            <v-card-title class="text-h5"
              >Apa anda akan mengirim informasi broadcast ke peserta
              ini?</v-card-title
            >
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="blue darken-1" text @click="closeDelete"
                >Tidak</v-btn
              >
              <v-btn color="blue darken-1" text @click="deleteItemConfirm"
                >Ya</v-btn
              >
              <v-spacer></v-spacer>
            </v-card-actions>
          </v-card>
        </v-dialog>

        <v-dialog v-model="dialogCopy" persistent max-width="800px">
          <v-card>
            <v-card-title>
              <span class="text-h5">Copy Peserta</span>
            </v-card-title>
            <v-card-text>
              <v-form
                ref="formCopy"
                v-model="valid"
                lazy-validation
                class="pl-4 pr-4"
              >
                <v-container>
                  <v-row>
                    <v-col cols="12">
                      <v-combobox
                        v-model="currentCopy.jenis_peserta"
                        label="Kategori"
                        clearable
                        dense
                        outlined
                        :items="items_jenis_peserta"
                        item-text="name"
                        :rules="currentCopy.jenisPesertaRules"
                        @change="changeCopyJenisPeserta"
                      ></v-combobox>
                    </v-col>

                    <v-col
                      v-if="
                        currentCopy &&
                        currentCopy.jenis_peserta &&
                        currentCopy.jenis_peserta.id == 1
                      "
                      cols="12"
                    >
                      <v-combobox
                        v-model="currentCopy.selectedDpw"
                        label="Dewan Pengurus Wilayah"
                        clearable
                        dense
                        outlined
                        :items="items_dpw"
                        item-text="nama"
                        @change="changeDpw"
                      ></v-combobox>

                      <v-combobox
                        v-model="currentCopy.selectedDpd"
                        label="Dewan Pengurus Daerah"
                        clearable
                        dense
                        outlined
                        :items="items_dpd"
                        item-text="nama"
                        @change="changeDpd"
                        @click="getListDpd"
                      ></v-combobox>

                      <v-combobox
                        v-model="currentCopy.selectedKomisariat"
                        label="Komisariat"
                        clearable
                        dense
                        outlined
                        :items="items_komisariat"
                        item-text="nama"
                        @click="getListKomisariat"
                      ></v-combobox>
                    </v-col>

                    <v-col
                      v-if="
                        currentCopy &&
                        currentCopy.jenis_peserta &&
                        currentCopy.jenis_peserta.id == 2
                      "
                      cols="12"
                    >
                      <v-combobox
                        v-model="currentCopy.event"
                        label="Nama Pelatihan"
                        clearable
                        dense
                        outlined
                        :items="items_event"
                        item-text="nama"
                        :rules="currentCopy.eventRules"
                        @click="openEventModal"
                      ></v-combobox>
                    </v-col>
                  </v-row>
                </v-container>
              </v-form>
            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="blue darken-1" text @click="dialogCopy = false">
                Close
              </v-btn>
              <v-btn color="blue darken-1" text @click="doSaveCopy">
                Save
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>

        <v-dialog v-model="dialogDeleteAll" max-width="600px">
          <v-card>
            <v-card-title class="text-h5"
              >Apa anda akan menghapus semua peserta ini?</v-card-title
            >
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="blue darken-1" text @click="closeDeleteAll"
                >Tidak</v-btn
              >
              <v-btn color="blue darken-1" text @click="deleteAllConfirm"
                >Ya</v-btn
              >
              <v-spacer></v-spacer>
            </v-card-actions>
          </v-card>
        </v-dialog>

        <v-dialog v-model="dialogRemove" max-width="600px">
          <v-card>
            <v-card-title class="text-h5"
              >Apa anda akan menghapus peserta ini?</v-card-title
            >
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="blue darken-1" text @click="closeRemove"
                >Tidak</v-btn
              >
              <v-btn color="blue darken-1" text @click="removeItemConfirm"
                >Ya</v-btn
              >
              <v-spacer></v-spacer>
            </v-card-actions>
          </v-card>
        </v-dialog>

        <v-dialog v-model="isDialogUpload" persistent max-width="800px">
          <v-card>
            <VForm ref="myForm" @submit.prevent="onSubmitUpload">
              <v-card-title>
                <span class="text-h5">Upload Peserta</span>
              </v-card-title>
              <v-card-text>
                <v-container>
                  <v-row>
                    <v-col cols="12">
                      <v-file-input
                        v-model="inputUploadFile"
                        accept=".xlsx, .xls"
                        prepend-icon="mdi-file"
                        show-size
                        label="Upload file excel"
                        :rules="uploadFileRules"
                      />
                    </v-col>
                  </v-row>
                </v-container>
              </v-card-text>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn
                  color="blue darken-1"
                  text
                  @click="isDialogUpload = false"
                >
                  Close
                </v-btn>
                <v-btn color="blue darken-1" text type="submit"> Save </v-btn>
              </v-card-actions>
            </VForm>
          </v-card>
        </v-dialog>
      </v-card-title>
      <v-data-table
        v-model="selected"
        :headers="headers"
        :items="list_data"
        :options.sync="options"
        :server-items-length="totalData"
        :loading="loading"
        class="elevation-1"
      >
        <template #[`item.status`]="{ item }">
          <v-chip v-if="item.status" :color="getColor(item.status)" dark>
            {{ getStatus(item.status) }}
          </v-chip>
        </template>
        <template #[`item.harga`]="{ item }">
          {{ new Intl.NumberFormat("id-ID").format(item.harga) }}
        </template>
        <template #[`item.actions`]="{ item }">
          <v-icon medium class="mr-3" @click="deleteItem(item)">
            mdi-send
          </v-icon>
          <v-icon medium class="mr-3" @click="editItem(item)">
            mdi-pencil
          </v-icon>
          <v-icon medium @click="removeItem(item)"> mdi-delete </v-icon>
        </template>
      </v-data-table>
    </v-card>

    <!-- Modal -->

    <EventSelectComponent
      ref="modalEvent"
      v-model="dialogEvent"
      @pilihEvent="pilihEvent"
    />
  </div>
</template>

<script>
import PibBroadcastUserService from "../services/PibBroadcastUserService";
import CommonService from "../services/CommonService";
import EventSelectComponent from "../components/PelatihanSelect.vue";

import KomisariatService from "../services/KomisariatService";
import DPWService from "../services/DPWService";
import DPDService from "../services/DPDService";

export default {
  components: { EventSelectComponent },
  props: {
    id: Number,
    pib_id: Number,
  },
  data: () => ({
    isProgressBarShow: false,
    childKey: 0,
    isEditable: 0,
    valid: true,
    search: "",
    selected: [],
    headers: [
      {
        text: "No",
        align: "start",
        sortable: false,
        value: "no",
      },
      { text: "Nama", value: "nama", sortable: false },
      { text: "Whatsapp", value: "whatsapp", sortable: false },
      { text: "Email", value: "email", sortable: false },
      { text: "Status", value: "status", sortable: false },
      { text: "Actions", value: "actions", sortable: false },
    ],
    isFirst: false,
    totalData: 0,
    list_data: [],
    loading: true,
    options: {
      itemsPerPage: 5,
      page: 1,
    },

    editedIndex: -1,
    editedItem: {},
    dialog: false,
    dialogDelete: false,
    dialogCopy: false,
    dialogDeleteAll: false,
    dialogRemove: false,

    currentData: {
      pelatihan_id: "",
      id: null,
      nama: "",
      email: "",
      whatsapp: "",
      namaRules: [(v) => !!v || "Nama harus diisi"],
      whatsappRules: [(v) => !!v || "Whatsapp harus diisi"],
    },

    currentCopy: {
      broadcast_id: "",
      jenis_peserta: "",
      pib: "",
      pib_id: "",
      selectedPropinsi: null,
      selectedKategori: null,
      selectedItem: null,
      event: "",
      event_id: "",
      selectedDpw: "",
      selectedDpd: "",
      selectedKomisariat: "",
      jenisPesertaRules: [(v) => !!v || "Jenis Peserta harus diisi"],
      pibRules: [(v) => !!v || "Nama PIB harus diisi"],
      cabangRules: [(v) => !!v || "Cabang harus diisi"],
      eventRules: [(v) => !!v || "Pelatihan harus diisi"],
    },

    items_komisariat: [],
    items_dpw: [],
    items_dpd: [],
    items_jenis_peserta: [
      {
        id: 1,
        name: "Anggota PPNI",
      },
      {
        id: 2,
        name: "Peserta Pelatihan",
      },
    ],
    items_event: [],
    dialogEvent: false,
    inputUploadFile: null,
    uploadFileRules: [(v) => !!v || "File upload harus diisi"],
    isDialogUpload: false,
  }),
  watch: {
    selected: {
      handler() {
        if (this.selected != undefined && this.selected[0]) {
          this.childKey++;
        }
      },
      deep: true,
    },
    options: {
      handler() {
        if (this.isFirst) {
          this.getDataFromApi();
        }
      },
      deep: true,
    },
  },
  mounted() {
    // this.getListPropinsi();
    this.getListDpw();
    this.getDataFromApi();
  },
  methods: {
    async getListDpw() {
      DPWService.dpwListAll()
        .then((res) => {
          this.items_dpw = res.data.list_data;
        })
        .catch((err) => {
          console.log(err);
        });
    },

    changeDpw() {
      this.selectedDpd = null;
      this.selectedKomisariat = null;
    },

    async getListDpd() {
      DPDService.dpdListAll(this.currentCopy.selectedDpw.id)
        .then((res) => {
          this.items_dpd = res.data.list_data;
        })
        .catch((err) => {
          console.log(err);
        });
    },

    changeDpd() {
      this.selectedKomisariat = null;
    },

    async getListKomisariat() {
      KomisariatService.komisariatListAllFilter(this.currentCopy.selectedDpd.id)
        .then((res) => {
          this.items_komisariat = res.data.list_data;
        })
        .catch((err) => {
          console.log(err);
        });
    },

    openEventModal: async function () {
      this.$refs.modalEvent.getDataFromApi();
      this.dialogEvent = true;
    },

    pilihEvent(item) {
      this.dialogEvent = false;
      console.log(item);
      this.currentCopy.event = item.kegiatan_title;
      this.currentCopy.event_id = item.kegiatan_id;
    },

    changeCopyJenisPeserta() {},

    getStatus(id) {
      if (id == 1) {
        return "Terkirim";
      } else if (id == 2) {
        return "Tidak Terkirim";
      }
    },

    getColor(dataStatus) {
      console.log(dataStatus);
      switch (dataStatus) {
        case 1:
          return "green";
        case 2:
          return "pink";
      }
    },

    async getDataFromApi() {
      const { page, itemsPerPage } = this.options;

      this.loading = true;
      PibBroadcastUserService.pesertaList(
        page,
        itemsPerPage,
        this.search,
        this.id,
        this.pib_id,
      )
        .then((res) => {
          this.list_data = res.data.list_data;
          this.totalData = res.data.total;
          this.loading = false;
          this.isFirst = true;
        })
        .catch((err) => {
          this.loading = false;
          this.isFirst = true;
          console.log(err);
        });
    },

    // openTambah() {
    //   this.currentData.id = null;
    //   this.dialog = true;
    //   this.currentData.nama = "";
    //   this.currentData.email = "";
    //   this.currentData.whatsapp = "";
    //   this.isEditable = 1;
    // },

    // openCopy() {
    //   this.dialogCopy = true;

    //   this.currentCopy.jenis_peserta = null;
    //   this.currentCopy.pib = "";
    //   this.currentCopy.pib_id = "";
    //   this.currentCopy.selectedDpw = null;
    //   this.currentCopy.selectedDpd = null;
    //   this.currentCopy.selectedKomisariat = null;
    // },

    downloadSample() {
      window.open(
        "https://api.pusdiklatpku.com/api/v1/admin/download/sample/upload",
        "_blank",
      );
    },

    openUpload() {
      this.inputUploadFile = null;
      this.isDialogUpload = true;
    },

    editItem(item) {
      console.log(item);
      this.editedIndex = this.list_data.indexOf(item);
      this.editedItem = Object.assign({}, item);

      this.dialog = true;
      console.log(this.editedItem);
      // this.currentData = this.editedItem;
      this.currentData.id = this.editedItem.id;
      this.currentData.nama = this.editedItem.nama;
      this.currentData.email = this.editedItem.email;
      this.currentData.whatsapp = this.editedItem.whatsapp;

      this.isEditable = 1;
    },

    doSave: async function () {
      if (!this.$refs.form.validate()) {
        return;
      }

      if (this.currentData.id == null) {
        this.currentData.broadcast_id = this.id;
        PibBroadcastUserService.create(this.currentData)
          .then((res) => {
            console.log(res);
            this.dialog = false;

            CommonService.showSuccessToast("Tambah Peserta berhasil");

            this.getDataFromApi();
          })
          .catch((err) => {
            console.log(err);

            CommonService.showErrorToast("Tambah Peserta gagal. Coba lagi");
          });
      } else {
        this.currentData.broadcast_id = this.id;

        PibBroadcastUserService.update(this.currentData)
          .then((res) => {
            this.dialog = false;

            console.log(res);

            CommonService.showSuccessToast("Update Peserta berhasil");

            this.getDataFromApi();
          })
          .catch((err) => {
            console.log(err);

            CommonService.showErrorToast("Update Peserta gagal. Coba lagi");
          });
      }
    },

    doSaveCopy() {
      if (!this.$refs.formCopy.validate()) {
        return;
      }

      this.isProgressBarShow = true;

      this.currentCopy.broadcast_id = this.id;
      PibBroadcastUserService.copyPeserta(this.currentCopy)
        .then((res) => {
          this.isProgressBarShow = false;
          console.log(res);
          this.dialogCopy = false;

          CommonService.showSuccessToast("Copy Peserta berhasil");

          this.getDataFromApi();
        })
        .catch((err) => {
          this.isProgressBarShow = false;
          console.log(err);

          CommonService.showErrorToast("Copy Peserta gagal. Coba lagi");
        });
    },

    deleteItem(item) {
      console.log(item);
      this.editedIndex = this.list_data.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.dialogDelete = true;
    },

    removeItem(item) {
      console.log(item);
      this.editedIndex = this.list_data.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.dialogRemove = true;
    },

    deleteItemConfirm() {
      this.closeDelete();
      this.isProgressBarShow = true;

      PibBroadcastUserService.send(this.editedItem.id, this.id)
        .then((res) => {
          this.isProgressBarShow = false;
          console.log(res);
          CommonService.showSuccessToast("Kirim Informasi Broadcast berhasil");

          this.getDataFromApi();
        })
        .catch((err) => {
          this.isProgressBarShow = false;
          console.log(err);
          CommonService.showErrorToast(
            "Kirim Informasi Broadcast gagal. Coba lagi",
          );
        });
    },

    closeDelete() {
      this.dialogDelete = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },

    openDeleteAll() {
      this.dialogDeleteAll = true;
    },

    closeDeleteAll() {
      this.dialogDeleteAll = false;
    },

    deleteAllConfirm() {
      this.closeDeleteAll();
      this.isProgressBarShow = true;

      PibBroadcastUserService.deleteAllPeserta(this.id)
        .then((res) => {
          this.isProgressBarShow = false;
          console.log(res);
          CommonService.showSuccessToast("Hapus Peserta berhasil");

          this.getDataFromApi();
        })
        .catch((err) => {
          this.isProgressBarShow = false;
          console.log(err);
          CommonService.showErrorToast("Hapus Peserta gagal. Coba lagi");
        });
    },

    closeRemove() {
      this.dialogRemove = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },

    removeItemConfirm() {
      this.closeRemove();
      this.isProgressBarShow = true;

      PibBroadcastUserService.remove(this.editedItem.id)
        .then((res) => {
          this.isProgressBarShow = false;
          console.log(res);
          CommonService.showSuccessToast("Hapus Peserta berhasil");

          this.getDataFromApi();
        })
        .catch((err) => {
          this.isProgressBarShow = false;
          console.log(err);
          CommonService.showErrorToast("Hapus Peserta gagal. Coba lagi");
        });
    },

    onSubmitUpload() {
      this.isProgressBarShow = true;

      PibBroadcastUserService.uploadPeserta({
        broadcast_id: this.id,
        file: this.inputUploadFile,
      })
        .then((res) => {
          this.isProgressBarShow = false;
          console.log(res);
          CommonService.showSuccessToast("Simpan data berhasil");

          this.isDialogUpload = false;
          this.getDataFromApi();
        })
        .catch((err) => {
          this.isProgressBarShow = false;
          console.log(err);

          CommonService.showErrorToast("Simpan data gagal. Coba lagi");
        });
    },
  },
};
</script>
