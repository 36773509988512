import http from "../../http-common";
/* eslint-disable */
class MasterValueService {
  jenisKemasan() {
    return http.get("/master/jenis-kemasan/list", {
      headers: {
        "Content-type": "application/json",
        Authorization:
          "Bearer " + localStorage.getItem(process.env.VUE_APP_TOKEN_PREFIX),
      },
    });
  }
}

export default new MasterValueService();
